import "./LoadingCover.scss";
import LoadingSpinner from "./../LoadingSpinner";

type LoadingCoverProps = {
    active: boolean
}

function LoadingCover(props: LoadingCoverProps) {
    if (!props.active) {
        return <></>;
    }

    return <div className="loading">
        <div className="loading__inner">
            <LoadingSpinner />
            <br />
            <span>Loading...</span>
        </div>
    </div>;
}

export default LoadingCover;