import { useState } from "react";
import { default as HeroGroupType } from "../../types/HeroGroup";
import HeroGrid from "../HeroGrid/HeroGrid";
import HeroTable from "../HeroTable/HeroTable";
import Tick from "../Tick/Tick";

import "./HeroGroup.scss";
import { useCollection, useDispatch, useSettings } from "../../reducer/hooks";
import { isInCollection } from "../../helpers/collectionHelper";

type HeroGroupProps = {
    group: HeroGroupType
}

function HeroGroup(props: HeroGroupProps) {
    const { collection } = useCollection();
    const dispatch = useDispatch();

    const { completedSectionStyle, displayType } = useSettings();

    const [collapsed, setCollapsed] = useState<boolean>(false);

    const setObtainedForAll = (obtained: boolean) => {
        const groupShortIds = props.group.heroes.map(x => x.shortId);

        dispatch({
            type: obtained ? "ADD_HEROES_TO_COLLECTION" : "REMOVE_HEROES_FROM_COLLECTION",
            shortIds: groupShortIds
        });
    };

    if (!props.group || !props.group.heroes || !props.group.heroes.length) return <></>;

    let allObtained = true;
    let obtainedCount = 0;
    for (var h of props.group.heroes) {
        if (!isInCollection(h.shortId, collection)) {
            allObtained = false;
        }
        else {
            obtainedCount += 1;
        }
    }

    const showWhenAllObtained = completedSectionStyle !== "hide";
    if (allObtained && !showWhenAllObtained) return <></>;

    let header = <></>;
    if (props.group.name) {
        const countAndTick = <div className="hero-group__count-tick-container">
            <span className="hero-group__counter">({obtainedCount}/{props.group.heroes.length})</span>
            <Tick checked={allObtained} onChange={() => setObtainedForAll(!allObtained)} />
        </div>

        header = <div className="hero-group__top">
            <div className="hero-group__top-left">
                <h3 className="hero-group__heading">{props.group.name}</h3>
                {countAndTick}
            </div>
            <div className="hero-group__top-right">
                {countAndTick}
                <button onClick={() => setCollapsed(!collapsed)}>{!collapsed ? "Collapse" : "Open"}</button>
            </div>
        </div>;
    }

    return <div key={props.group.id} className="hero-group">
        {header}
        {!collapsed && (displayType === "grid"
            ? <HeroGrid heroes={props.group.heroes ?? []} />
            : <HeroTable heroes={props.group.heroes ?? []} />)}
    </div>
}

export default HeroGroup;