import HeroGroup from "../../types/HeroGroup";
import HeroChecklistGroup from "../HeroChecklistGroup";

import "./HeroChecklistBook.scss";
import HeroSourceIds from "../../lookups/HeroSourceIds";
import HeroAbilityIds from "../../lookups/HeroAbilityIds";

type HeroChecklistBookProps = {
    heroGroup: HeroGroup,
    twoColumns: boolean
}

function HeroChecklistBook(props: HeroChecklistBookProps) {
    let legendaryHeroes = [];
    let mythicHeroes = [];
    let emblemHeroes = [];
    let tempestTrialHeroes = [];
    let grandHeroBattleHeroes = [];
    const generalHeroes = [];

    for (let hero of props.heroGroup.heroes) {
        // TT Heroes
        if (hero.heroSource.id === HeroSourceIds.TempestTrial) {
            grandHeroBattleHeroes.push(hero);
            continue;
        }
        // GHB Heroes
        if (hero.heroSource.id === HeroSourceIds.GrandHeroBattle) {
            tempestTrialHeroes.push(hero);
            continue;
        }
        // Legendary Heroes
        if (hero.heroAbility.id === HeroAbilityIds.Legendary) {
            legendaryHeroes.push(hero);
            continue;
        }
        // Mythic Heroes
        if (hero.heroAbility.id === HeroAbilityIds.Mythic) {
            mythicHeroes.push(hero);
            continue;
        }
        // Emblem Heroes
        if (hero.heroAbility.id === HeroAbilityIds.Emblem) {
            emblemHeroes.push(hero);
            continue;
        }

        generalHeroes.push(hero);
    }

    legendaryHeroes = legendaryHeroes.reverse();
    mythicHeroes = mythicHeroes.reverse();
    emblemHeroes = emblemHeroes.reverse();
    tempestTrialHeroes = tempestTrialHeroes.reverse();
    grandHeroBattleHeroes = grandHeroBattleHeroes.reverse();

    let bannerGroups: HeroGroup[] = [];
    for (let hero of generalHeroes) {
        let shortId = (hero.banner?.shortId ?? "").toString();
        if (!shortId) continue;

        let bannerGroup = bannerGroups.find(x => x.id === shortId);
        if (!bannerGroup) {
            bannerGroup = {
                id: shortId,
                name: hero.banner?.name ?? "Group",
                heroes: []
            }
            bannerGroups.push(bannerGroup);
        }

        bannerGroup.heroes.push(hero);
    }

    bannerGroups = bannerGroups.reverse();

    const secondColumnContent = <>
        {!!legendaryHeroes.length && <HeroChecklistGroup heroGroup={{ id: "", name: "Legendary Heroes", heroes: legendaryHeroes }} side={true} base={!props.twoColumns} />}
        {!!mythicHeroes.length && <HeroChecklistGroup heroGroup={{ id: "", name: "Mythic Heroes", heroes: mythicHeroes }} side={true} base={!props.twoColumns} />}
        {!!emblemHeroes.length && <HeroChecklistGroup heroGroup={{ id: "", name: "Emblem Heroes", heroes: emblemHeroes }} side={true} base={!props.twoColumns} />}
        {!!grandHeroBattleHeroes.length && <HeroChecklistGroup heroGroup={{ id: "", name: "GHB Heroes", heroes: grandHeroBattleHeroes }} side={true} base={!props.twoColumns} />}
        {!!tempestTrialHeroes.length && <HeroChecklistGroup heroGroup={{ id: "", name: "Tempest Trial Heroes", heroes: tempestTrialHeroes }} side={true} base={!props.twoColumns} />}
    </>;

    let mainDivClasses = "checklist__main-heroes";
    if (!props.twoColumns) {
        mainDivClasses += " checklist__main-heroes--full-width";
    }

    return <>
        <hr />
        <h3>{props.heroGroup.name}</h3>
        <div key={`herochecklistbookdiv-${props.heroGroup.name.replaceAll(" ", "")}`} className={mainDivClasses}>
            {bannerGroups.map(x => {
                return <HeroChecklistGroup heroGroup={x} side={false} base={!props.twoColumns} />
            })}
            {!props.twoColumns && secondColumnContent}
        </div>
        {props.twoColumns && <div className="checklist__side-heroes">
            {secondColumnContent}
        </div>}
    </>
}

export default HeroChecklistBook;