type KeyListenerProps = {
    chain: string[],
    action: () => void
}

function KeyListener(props: KeyListenerProps) {
    const body = document.querySelector("body") as HTMLBodyElement;
    if (!body.dataset["keylistenerbound"]) {
        body.addEventListener("keyup", (e: KeyboardEvent) => {
            let newChain = body.dataset["chain"]?.split("¬") ?? [];
            newChain.push(e.key);

            const targetLength = props.chain.length;
            if (newChain.length > targetLength) {
                newChain = newChain.splice(newChain.length - targetLength, targetLength);
            }

            let allMatch = true;
            for (let i = 0; i < props.chain.length; i++) {
                if (props.chain[i] !== newChain[i]) {
                    allMatch = false;
                }
            }

            if (allMatch) {
                props.action();
            }

            body.dataset["chain"] = newChain.join("¬");
        })

        body.dataset["keylistenerbound"] = "true";
    }

    return <></>;
}

export default KeyListener;