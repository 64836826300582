import ApiResponse from "../types/ApiResponse";
import BannerInfo from "../types/BannerInfo";
import FeatInfo from "../types/FeatInfo";
import GameInfo from "../types/GameInfo";
import HeroAbility from "../types/HeroAbility";
import HeroColor from "../types/HeroColor";
import HeroInfo from "../types/HeroInfo";
import HeroMoveType from "../types/HeroMoveType";
import HeroSource from "../types/HeroSource";
import HeroSummoningPool from "../types/HeroSummoningPool";
import HeroTheme from "../types/HeroTheme";
import HeroWeapon from "../types/HeroWeapon";
import ImportableHero from "../types/ImportableHero";

function getApiRoute(endpoint: string) {
    return `${process.env.REACT_APP_API_URL}/${endpoint}`;
}

async function makeGetCall<T>(url: string, headers: [string, string][] = []): Promise<ApiResponse<T>> {
    const response = await fetch(url, {
        headers
    });
    if (response.ok) {
        const data = await response.json() as T;
        return {
            data,
            success: true
        }
    }

    return {
        data: null,
        success: false,
        message: `${response.status} ${response.statusText}`.trim()
    }
}

async function processGetCall<T>(endpoint: string, adminPassword: string = ""): Promise<T | null> {
    const headers: [string, string][] = [];
    if (!!adminPassword) {
        headers.push(["password", adminPassword]);
    }

    const url = getApiRoute(endpoint);
    const response = await makeGetCall<T>(url, headers);

    if (!response.success) {
        throw `Failed to fetch from : ${response.message}`;
    }

    return response.data;
}

async function getHeroes(): Promise<HeroInfo[]> {
    return await processGetCall("Hero") ?? [];
}

async function getBanners(): Promise<BannerInfo[]> {
    return await processGetCall("Banner") ?? [];
}

async function getGames(): Promise<GameInfo[]> {
    return await processGetCall("Game") ?? [];
}

async function getHeroAbilities(): Promise<HeroAbility[]> {
    return await processGetCall("HeroAbility") ?? [];
}

async function getHeroColors(): Promise<HeroColor[]> {
    return await processGetCall("HeroColor") ?? [];
}

async function getHeroMoveTypes(): Promise<HeroMoveType[]> {
    return await processGetCall("HeroMoveType") ?? [];
}

async function getHeroSources(): Promise<HeroSource[]> {
    return await processGetCall("HeroSource") ?? [];
}

async function getHeroSummoningPools(): Promise<HeroSummoningPool[]> {
    return await processGetCall("HeroSummoningPool") ?? [];
}

async function getHeroThemes(): Promise<HeroTheme[]> {
    return await processGetCall("HeroTheme") ?? [];
}

async function getHeroWeapons(): Promise<HeroWeapon[]> {
    return await processGetCall("HeroWeapon") ?? [];
}

async function getFeats(): Promise<FeatInfo[]> {
    return await processGetCall("Feat") ?? [];
}

async function getScrapeList(adminPassword: string): Promise<ImportableHero[]> {
    return await processGetCall("Scrape/List", adminPassword) ?? [];
}

export {
    getHeroes,
    getBanners,
    getGames,
    getHeroAbilities,
    getHeroColors,
    getHeroMoveTypes,
    getHeroSources,
    getHeroSummoningPools,
    getHeroThemes,
    getHeroWeapons,
    getFeats,
    getScrapeList
};