import GroupingField from "../../lookups/GroupingField";
import SelectDataField from "../SelectDataField";
import { SelectDataFieldItem } from "../SelectDataField/SelectDataField";

type HeroGroupPanelProps = {
  groupingField: GroupingField;
  setGroupingField: (field: GroupingField) => void;
};

function HeroGroupPanel(props: HeroGroupPanelProps) {
  const groupingFieldsList: GroupingField[] = [
    "Banner",
    "Character",
    "Color",
    "Weapon",
    "Move Type",
    "Ability",
    "Source",
    "Theme",
    "Game",
    "Book",
    "Chapter",
  ];

  const groupingOptions: SelectDataFieldItem[] = [
    {
      text: "",
      value: "None",
    },
    ...groupingFieldsList.map((x) => {
      return {
        text: x,
        value: x,
      };
    }),
  ];

  return (
    <>
      <SelectDataField
        label="Group heroes by"
        value={props.groupingField}
        onChange={(value) => {
          if (groupingFieldsList.includes(value as GroupingField)) {
            props.setGroupingField(value as GroupingField);
          } else {
            props.setGroupingField("None");
          }
        }}
        options={groupingOptions}
      />
    </>
  );
}

export default HeroGroupPanel;
