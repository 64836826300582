import HeroFilterableFields from "../../types/HeroFilterableFields";
import { Action } from "../actions";

export type FiltersState = HeroFilterableFields & {

};

export const filtersInitialState: FiltersState = {
    name: "",
    heroColors: [],
    heroWeapons: [],
    heroMoveTypes: [],
    heroAbilities: [],
    heroSources: [],
    heroSummoningPools: [],
    heroThemes: [],
    books: [],
    games: [],
    obtained: undefined,
    wishlisted: undefined,
}

export const filtersReducer = (state: FiltersState, action: Action): FiltersState => {
    switch (action.type) {
        case "SET_FILTERS":
            return { ...action.filters };
        case "RESET_FILTERS":
            return filtersInitialState;
        default:
            return state;
    }
}
