import { useEffect } from "react";
import { useDispatch } from "../reducer/hooks";
import PageTitle from "../components/PageTitle";

function About() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: false
        });
    }, []);

    return <>
        <PageTitle>About</PageTitle>
        <p>This website allows you to track your collection of heroes from the Fire Emblem Heroes game! You can view a list of heroes in the game, set them as either obtained or unobtained, or add them to your wishlist.</p>
        <p>Your collection can be filtered by the hero's name, origin game, move type, weapon type, summoning pool and more!</p>
        <p>You can view a full Checklist of the heroes you don't have, or see how what Feats you have unlocked by collecting heroes.</p>

        <h3>Storage</h3>
        <p>This website uses your browser's local storage to track the state of your collection. If you clear your browser's storage, your collection will be lost.</p>
        <p>You can export your collection as a file using the Settings page, which can then be used to move the collection to another browser/computer, or to keep as a backup.</p>
        <p>No personal information is collected by this site, nor is any information shared with third parties.</p>

        <h3>Updates / Future Development</h3>
        <p>I currently maintain this site solo, but my goal is to keep the hero roster updated as new heroes are announced!</p>
        <p>Over time, I may also be able to add new pages/features, but as this is mainly a personal project I can't guarantee this.</p>

        <h3>Contact</h3>
        <p>If you encounter any issues with the website, or need to contact me, you can reach me at <a href="mailto:fehcollectordev@gmail.com">fehcollectordev@gmail.com</a>.</p>

        <h3>Resources used</h3>
        <p>Characters and images from the Fire Emblem Heroes mobile game.</p>
        <p>Sources used:</p>
        <ul>
            <li><a href="https://gamepress.gg/feheroes/" target="_blank" rel="noopener">GamePress</a></li>
            <li><a href="https://feheroes.fandom.com/wiki/Main_Page" target="_blank" rel="noopener">the Fire Emblem Heroes Wiki on Fandom</a></li>
            <li><a href="https://game8.co/games/fire-emblem-heroes" target="_blank" rel="noopener">Game8.co</a></li>
        </ul>
    </>;
}

export default About;