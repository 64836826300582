import "./ProgressBar.scss"

type ProgressBarProps = {
    count: number,
    maximum: number
}

function ProgressBar(props: ProgressBarProps) {
    const percentage = Math.min(Math.max(!!props.maximum ? (props.count / props.maximum * 100) : 0, 0), 100).toFixed(2);

    return <div className="progress-bar__outer">
        {!!props.maximum &&
            <>
                <div className="progress-bar__inner" style={{ width: `${percentage}%` }}></div>
                <div className="progress-bar__text-container">
                    <span className="progress-bar__text">{Math.min(Math.max(props.count, 0), props.maximum)}/{props.maximum} ({percentage}%)</span>
                </div>
            </>
        }
    </div>
}

export default ProgressBar;