import { NavLink } from "react-router-dom";

import "./ManageTile.scss";

type ManageTileProps = {
    text: string,
    path: string
}

function ManageTile(props: ManageTileProps) {
    return <NavLink className="manage-tile" to={props.path}>{props.text}</NavLink>
}

export default ManageTile;