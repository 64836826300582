import { loadFromLocalStorage, saveToLocalStorage } from "../../helpers/localStorageHelper";
import { adminPasswordKey } from "../../lookups/LocalStorage";
import { Action } from "../actions";

export type AdminState = {
    adminPassword: string
};

export const adminInitialState: AdminState = {
    adminPassword: ""
}

export const adminReducer = (state: AdminState, action: Action): AdminState => {
    switch (action.type) {
        case "SET_ADMIN_PASSWORD":
            saveToLocalStorage(adminPasswordKey, action.password);
            return { ...state, adminPassword: action.password };
        case "LOAD_ADMIN_PASSWORD":
            const storedPassword = loadFromLocalStorage<string>(adminPasswordKey);
            return { ...state, adminPassword: storedPassword ?? "" };
        default:
            return state;
    }
}