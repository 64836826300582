import { ChangeEvent } from "react";
import { useDispatch, useFilters, useSiteData } from "../../reducer/hooks";
import DataField from "../DataField";
import TextDataField from "../TextDataField";

type HeroFilterPanelProps = {
  bookOptions: number[];
};

function HeroFilterPanel(props: HeroFilterPanelProps) {
  const siteData = useSiteData();
  const filters = useFilters();
  const dispatch = useDispatch();

  return (
    <>
      <TextDataField
        label="Name"
        value={filters.name}
        onChange={(value) => {
          dispatch({
            type: "SET_FILTERS",
            filters: { ...filters, name: value },
          });
        }}
      />
      <DataField label="Colour">
        <select
          value={filters.heroColors[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroColors:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="color-none" value="">
            Any
          </option>
          {siteData.heroColors &&
            siteData.heroColors.map((item) => {
              return (
                <option key={`color-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Weapon">
        <select
          value={filters.heroWeapons[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroWeapons:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="weapon-none" value="">
            Any
          </option>
          {siteData.heroWeapons &&
            siteData.heroWeapons.map((item) => {
              return (
                <option key={`weapon-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Move Type">
        <select
          value={filters.heroMoveTypes[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroMoveTypes:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="movetype-none" value="">
            Any
          </option>
          {siteData.heroMoveTypes &&
            siteData.heroMoveTypes.map((item) => {
              return (
                <option key={`movetype-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Ability">
        <select
          value={filters.heroAbilities[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroAbilities:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="ability-none" value="">
            Any
          </option>
          {siteData.heroAbilities &&
            siteData.heroAbilities.map((item) => {
              return (
                <option key={`ability-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Source">
        <select
          value={filters.heroSources[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroSources:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="source-none" value="">
            Any
          </option>
          {siteData.heroSources &&
            siteData.heroSources.map((item) => {
              return (
                <option key={`source-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Summoning Pool">
        <select
          value={filters.heroSummoningPools[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroSummoningPools:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="summon-none" value="">
            Any
          </option>
          {siteData.heroSummoningPools &&
            siteData.heroSummoningPools.map((item) => {
              return (
                <option key={`summon-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Theme">
        <select
          value={filters.heroThemes[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              heroThemes:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="theme-none" value="">
            Any
          </option>
          {siteData.heroThemes &&
            siteData.heroThemes.map((item) => {
              return (
                <option key={`theme-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Game">
        <select
          value={filters.games[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              games:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="firstGame-none" value="">
            Any
          </option>
          {siteData.games &&
            siteData.games.map((item) => {
              return (
                <option key={`firstGame-${item.id}`} value={item.id}>
                  {item.title}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Book">
        <select
          value={filters.books[0]}
          onChange={(event: ChangeEvent) => {
            const selectedValue = parseInt((event.target as any).value ?? "0");
            const newFilters = {
              ...filters,
              books:
                !!selectedValue && selectedValue > 0 ? [selectedValue] : [],
            };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="book-none" value="">
            Any
          </option>
          {props.bookOptions &&
            props.bookOptions.map((bookNumber) => {
              return (
                <option key={`book-${bookNumber}`} value={bookNumber}>
                  {bookNumber}
                </option>
              );
            })}
        </select>
      </DataField>
      <DataField label="Obtained?">
        <select
          value={filters.obtained?.toString() ?? ""}
          onChange={(event: ChangeEvent) => {
            const selectedValue = (event.target as any).value ?? "";
            const booleanValue = !!!selectedValue
              ? undefined
              : selectedValue === "false"
              ? false
              : true;
            const newFilters = { ...filters, obtained: booleanValue };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="obtained-none" value="">
            Either
          </option>
          <option key="obtained-yes" value="true">
            Yes
          </option>
          <option key="obtained-no" value="false">
            No
          </option>
        </select>
      </DataField>
      <DataField label="Wishlisted?">
        <select
          value={filters.wishlisted?.toString() ?? ""}
          onChange={(event: ChangeEvent) => {
            const selectedValue = (event.target as any).value ?? "";
            const booleanValue = !!!selectedValue
              ? undefined
              : selectedValue === "false"
              ? false
              : true;
            const newFilters = { ...filters, wishlisted: booleanValue };
            dispatch({ type: "SET_FILTERS", filters: newFilters });
          }}
        >
          <option key="wishlisted-none" value="">
            Either
          </option>
          <option key="wishlisted-yes" value="true">
            Yes
          </option>
          <option key="wishlisted-no" value="false">
            No
          </option>
        </select>
      </DataField>
    </>
  );
}

export default HeroFilterPanel;
