import HeroInfo from "../../types/HeroInfo";
import { useCollection, useDispatch, useSettings } from "../../reducer/hooks";
import { isInCollection, isInWishlist } from "../../helpers/collectionHelper";

import "./HeroGrid.scss";
import HeroGridTile from "../HeroGridTile";

type HeroGridProps = {
  heroes: HeroInfo[]
}

function HeroGrid(props: HeroGridProps) {
  return (
    <div className="hero-grid">
      {props.heroes.map((hero) => {
        return <HeroGridTile key={`hero-grid-tile-${hero.shortId}`} hero={hero} />
      })}
    </div>
  );
}

export default HeroGrid;