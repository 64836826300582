import { CollectionData, CollectionHeroes, WishlistHeroes } from "../types/CollectionData";
import CollectionSaveFile from "../types/CollectionSaveFile";

export const isInCollection = (shortId: string, collection: CollectionHeroes) => {
    return collection.has(shortId);
}

export const isInWishlist = (shortId: string, wishlist: WishlistHeroes) => {
    return wishlist.has(shortId);
}

export const getCollectionCount = (collection: CollectionHeroes) => {
    return Array.from(collection.entries()).length;
}

export const convertSaveFileIntoData = (file: CollectionSaveFile) => {
    const collectionData: CollectionData = {
        version: file.version,
        collection: new Map(file.collection),
        wishlist: new Map(file.wishlist)
    };
    return collectionData;
}

export const convertDataIntoSaveFile = (data: CollectionData) => {
    const collectionSaveFile: CollectionSaveFile = {
        version: data.version,
        collection: [],
        wishlist: []
    };

    if (!!data.collection.entries) {
        collectionSaveFile.collection = Array.from(data.collection.entries())
    }
    if (!!data.wishlist.entries) {
        collectionSaveFile.wishlist = Array.from(data.wishlist.entries())
    }

    return collectionSaveFile;
}