import Globals from "../lookups/Globals";
import GroupSortField from "../lookups/GroupSortField";
import GroupingField from "../lookups/GroupingField";
import HeroSortField from "../lookups/HeroSortField";
import { CollectionData } from "../types/CollectionData";
import GameInfo from "../types/GameInfo";
import HeroFilterableFields from "../types/HeroFilterableFields";
import HeroGroup from "../types/HeroGroup";
import HeroInfo from "../types/HeroInfo";
import { isInCollection, isInWishlist } from "./collectionHelper";

const buildHeroFilter = (fields: HeroFilterableFields, collectionData: CollectionData) => {
    return (hero: HeroInfo) => {
        if (fields.heroColors.length && fields.heroColors.indexOf(hero.heroColor.id) <= -1) return false;
        if (fields.heroWeapons.length && fields.heroWeapons.indexOf(hero.heroWeapon.id) <= -1) return false;
        if (fields.heroMoveTypes.length && fields.heroMoveTypes.indexOf(hero.heroMoveType.id) <= -1) return false;
        if (fields.heroAbilities.length && fields.heroAbilities.indexOf(hero.heroAbility.id) <= -1) return false;
        if (fields.heroSources.length && fields.heroSources.indexOf(hero.heroSource.id) <= -1) return false;
        if (fields.heroSummoningPools.length && fields.heroSummoningPools.indexOf(hero.heroSummoningPool.id) <= -1) return false;
        if (fields.heroThemes.length && fields.heroThemes.indexOf(hero.heroTheme?.id ?? 0) <= -1) return false;
        if (fields.games.length && fields.games.indexOf(hero.heroGames[0]?.gameId ?? 0) <= -1) return false;
        if (fields.books.length && fields.books.indexOf(hero.bookNumber ?? 0) <= -1) return false;

        if (fields.obtained !== undefined) {
            if (fields.obtained === true && !isInCollection(hero.shortId, collectionData.collection)) return false;
            if (fields.obtained === false && isInCollection(hero.shortId, collectionData.collection)) return false;
        }

        if (fields.wishlisted !== undefined) {
            if (fields.wishlisted === true && !isInWishlist(hero.shortId, collectionData.wishlist)) return false;
            if (fields.wishlisted === false && isInWishlist(hero.shortId, collectionData.wishlist)) return false;
        }

        if (!!fields.name) {
            const cleanFilterName = fields.name.toLowerCase().trim();
            const cleanHeroName = hero.heroName.toLowerCase().trim();
            const cleanCharacterName = hero.characterName.toLowerCase().trim();

            const heroNameMatch = cleanHeroName.includes(cleanFilterName);
            const characterNameMatch = cleanCharacterName.includes(cleanFilterName);

            if (!heroNameMatch && !characterNameMatch) return false;
        }

        return true;
    };
}

type LookupData = {
    games: GameInfo[]
}

const groupHeroesSimple = (heroes: HeroInfo[], detailsGetter: (hero: HeroInfo) => [string, string], options: {
    skipFalsyIds: boolean
} = {
        skipFalsyIds: false
    }) => {
    const groups: HeroGroup[] = [];

    for (let hero of heroes) {
        const [id, name] = detailsGetter(hero);
        console.log(id);
        if (options.skipFalsyIds && (!id || id === "0")) {
            continue;
        }

        let group = groups.find(x => x.id === id);
        if (!group) {
            group = {
                id,
                name,
                heroes: []
            }
            groups.push(group);
        }

        group.heroes.push(hero);
    }

    return groups;
}

const groupHeroes = (heroes: HeroInfo[], field: GroupingField, lookupData: LookupData | undefined) => {
    let groups: HeroGroup[] = [];
    if (!heroes || !heroes.length) return groups;

    // Handle non-Hero based groupings
    if (field === "None") {
        groups.push({ id: "all", name: "", heroes: heroes });
        return groups;
    }

    switch (field) {
        case "Character":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = hero.characterName ?? "No character name";
                return [id, id];
            });
            break;
        case "Source":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = "-1";
                let name = "No source";
                if (hero.heroSource.id !== 1) {
                    id = (hero.heroSource.id ?? 0).toString();
                    name = hero.heroSource.name ?? name;
                }
                else {
                    id = `${hero.heroSource.id}-${hero.heroSummoningPool.id}`;
                    name = `${hero.heroSource.name} - ${hero.heroSummoningPool.name}`;
                }

                return [id, name || "No source"];
            });
            break;
        case "Ability":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = (hero.heroAbility?.id ?? 0).toString();
                let name = hero.heroAbility?.name ?? "No ability";
                return [id, name];
            });
            break;
        case "Banner":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = hero.banner?.shortId ?? "";
                let name = hero.banner?.name ?? "No banner";
                return [id, name];
            }, {
                skipFalsyIds: true
            });
            break;
        case "Game":
            if (!!!lookupData) {
                throw new Error("No lookup data available");
            }

            for (let hero of heroes) {
                // Heroes not assigned a game
                if (!hero.heroGames || !hero.heroGames.length) {
                    let group = groups.find(x => x.id === "0");
                    if (!group) {
                        group = {
                            id: "-1",
                            name: "No game",
                            heroes: []
                        }
                        groups.push(group);
                    }

                    group.heroes.push(hero);

                    continue;
                }

                // Heroes assigned a game
                for (let game of hero.heroGames) {
                    let id = (game?.gameId ?? 0).toString();
                    let group = groups.find(x => x.id === id);
                    if (!group) {
                        const gameInfo = lookupData.games.find(x => x.id.toString() === id);

                        group = {
                            id,
                            name: gameInfo?.title ?? "Game unknown",
                            heroes: []
                        }
                        groups.push(group);
                    }

                    group.heroes.push(hero);
                }
            }
            break;
        case "Theme":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = (hero.heroTheme?.id ?? 0).toString();
                let name = hero.heroTheme?.name ?? "No theme";
                return [id, name];
            }, {
                skipFalsyIds: true
            });
            break;
        case "Color":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = (hero.heroColor?.id ?? 0).toString();
                let name = hero.heroColor?.name ?? "No color";
                return [id, name];
            });
            break;
        case "Move Type":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = (hero.heroMoveType?.id ?? 0).toString();
                let name = hero.heroMoveType?.name ?? "No move type";
                return [id, name];
            });
            break;
        case "Weapon":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = (hero.heroWeapon?.id ?? 0).toString();
                let name = hero.heroWeapon?.name ?? "No weapon";
                return [id, name];
            });
            break;
        case "Book":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = (hero.bookNumber ?? 0).toString();
                let name = `Book ${id}`;
                return [id, name];
            });
            break;
        case "Chapter":
            groups = groupHeroesSimple(heroes, (hero) => {
                let id = `${(hero.bookNumber ?? 0)}.${(hero.chapterNumber ?? 0)}`;
                let name = `Chapter ${id}` ?? "No book/chapter";
                return [id, name];
            });
            break;
    }

    return groups;
}

const sortGroupsAndHeroes = (groups: HeroGroup[], heroSortField: HeroSortField, groupSortField: GroupSortField) => {
    const sortedGroups = groups.sort((a, b) => {
        switch (groupSortField) {
            case "Count":
                return b.heroes.length - a.heroes.length;
            case "Name":
                if (a.name > b.name) {
                    return 1;
                }
                else if (a.name < b.name) {
                    return -1;
                }
                return 0;
            case "Banner Release Date":
                const dateA = a.heroes[0]?.banner?.startDate ?? Globals.FEH_APP_LAUNCH_DATE;
                const dateB = b.heroes[0]?.banner?.startDate ?? Globals.FEH_APP_LAUNCH_DATE;

                if (dateA < dateB) {
                    return 1;
                }
                else if (dateB < dateA) {
                    return -1;
                }
                return 0;
            case "Book Number":
                return (b.heroes[0].bookNumber ?? 0) - (a.heroes[0].bookNumber ?? 0);
            case "Chapter Number":
                const bookCompare = (b.heroes[0].bookNumber ?? 0) - (a.heroes[0].bookNumber ?? 0);
                if (bookCompare !== 0) return bookCompare;

                return (b.heroes[0].chapterNumber ?? 0) - (a.heroes[0].chapterNumber ?? 0);
            default:
                return 0;
        }
    })
    sortedGroups.forEach(group => {
        group.heroes = group.heroes.sort((a, b) => {
            switch (heroSortField) {
                case "Character Name":
                    if (a.characterName > b.characterName) {
                        return 1;
                    }
                    else if (a.characterName < b.characterName) {
                        return -1;
                    }
                    return 0;
                case "Release Date":
                    const dateA = a.firstAvailable ?? Globals.FEH_APP_LAUNCH_DATE;
                    const dateB = b.firstAvailable ?? Globals.FEH_APP_LAUNCH_DATE;
                    if (dateA < dateB) {
                        return 1;
                    }
                    else if (dateB < dateA) {
                        return -1;
                    }
                    return 0;
                default:
                    return 0;
            }
        })
    });
    return sortedGroups;
}

export {
    buildHeroFilter,
    groupHeroes,
    sortGroupsAndHeroes
}