import { loadFromLocalStorage, saveToLocalStorage } from "../../helpers/localStorageHelper";
import { siteSettingsKey } from "../../lookups/LocalStorage";
import SiteSettings from "../../types/SiteSettings";
import { Action } from "../actions";

export type SettingsState = SiteSettings & {

};

export const settingsInitialState: SettingsState = {
    displayType: "grid",
    obtainedArt: "special",
    unobtainedArt: "attack",
    obtainedStyle: "show",
    unobtainedStyle: "fade",
    completedSectionStyle: "show"
}

export const settingsReducer = (state: SettingsState, action: Action): SettingsState => {
    switch (action.type) {
        case "SET_SETTINGS":
            saveToLocalStorage(siteSettingsKey, action.settings);
            return { ...action.settings };
        case "LOAD_SETTINGS":
            const storedSettings = loadFromLocalStorage<SiteSettings>(siteSettingsKey);
            return { ...settingsInitialState, ...storedSettings };
        default:
            return state;
    }
}