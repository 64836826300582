const featObtainTypes = Object.freeze({
    CharacterCount: 1,                 // Lyn Emblem
    BookPercentage: 2,                 // 100% of Book 1 units
    BookCount: 3,                      // 50 Book 1 units
    GamePercentage: 4,                 // 50% of Shadow Dragon units
    GameCount: 5,                      // 40 Shadow Dragon units
    ThemePercentage: 6,                // 50% of Summer units
    ThemeCount: 7,                     // 20 Summer units
    SummoningPoolPercentage: 8,        // 50% GHB victories
    SummoningPoolCount: 9,             // 50 GHB victories
    AbilityPercentage: 10,             // 25% of Legendary heroes
    AbilityCount: 11,                  // 20 Legendary heroes
    WholeCollectionCount: 12,          // 776 total heroes
    WholeCollectionPercentage: 13      // 100% of total heroes
});

export default featObtainTypes;