import { useState } from "react";
import { NavLink } from "react-router-dom";
import HeroSortField from "../../lookups/HeroSortField";
import GroupSortField from "../../lookups/GroupSortField";
import { buildHeroFilter, sortGroupsAndHeroes } from "../../helpers/heroHelper";
import HeroFilterPanel from "../../components/HeroFilterPanel/HeroFilterPanel";
import HeroSortPanel from "../../components/HeroSortPanel/HeroSortPanel";
import { formatDateAsString } from "../../helpers/dateHelper";
import { useCollection, useFilters, useSiteData } from "../../reducer/hooks";
import PageTitle from "../../components/PageTitle";

function ManageHeroes() {
  const { heroes, games } = useSiteData();
  const filters = useFilters();
  const collection = useCollection();

  const [heroSortField, setHeroSortField] =
    useState<HeroSortField>("Release Date");
  const [groupSortField, setGroupSortField] = useState<GroupSortField>("Count");

  const books = Array.from(
    new Set(heroes.map((x) => x.bookNumber).filter((x) => !!x)).values(),
  ).sort() as number[];

  // Step 1: Filter
  const filterHeroes = buildHeroFilter(filters, collection);
  const filteredHeroes = heroes.filter(filterHeroes);

  // Step 2: Sort
  let groups = sortGroupsAndHeroes(
    [{ id: "", name: "", heroes: filteredHeroes }],
    heroSortField,
    groupSortField,
  );

  return (
    <>
      <PageTitle>Manage Heroes</PageTitle>

      <h3>Filters</h3>
      <HeroFilterPanel bookOptions={books} />
      <h3>Sorting</h3>
      <HeroSortPanel
        heroSortField={heroSortField}
        setHeroSortField={setHeroSortField}
        groupSortField={groupSortField}
        setGroupSortField={setGroupSortField}
        groupingFieldHint={"None"}
      />
      <hr />
      <NavLink className="button" to="/Manage/Hero/">
        Add
      </NavLink>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Weapon Type</th>
            <th>Move Type</th>
            <th>First available</th>
            <th>Source</th>
            <th>Game(s)</th>
          </tr>
        </thead>
        <tbody>
          {groups[0].heroes.map((hero) => {
            const gameNames = [];
            for (let heroGame of hero.heroGames) {
              const game = games.find((x) => x.id === heroGame.gameId);
              if (!!game) {
                gameNames.push(game.title);
              }
            }

            return (
              <tr key={hero.shortId}>
                <td>
                  <NavLink to={`/Manage/Hero/${hero.shortId}`}>
                    {hero.characterName}: {hero.title}
                  </NavLink>
                </td>
                <td>
                  <img
                    className="weapon-icon"
                    src={`/icons/weapons/${hero.heroColor.name}${hero.heroWeapon.name}.png`}
                    alt={hero.heroWeapon.name}
                  />
                </td>
                <td>
                  <img
                    className="move-type-icon"
                    src={`/icons/movetypes/${hero.heroMoveType.name}.png`}
                    alt={hero.heroMoveType.name}
                  />
                </td>
                <td>{formatDateAsString(hero.firstAvailable)}</td>
                <td>
                  {hero.heroSource.name}{" "}
                  {hero.heroSource.id === 1
                    ? ` - ${hero.heroSummoningPool.name}`
                    : ""}
                </td>
                <td>
                  <span>
                    {gameNames.map((gameName) => (
                      <>
                        <span>{gameName}</span>
                        <br />
                      </>
                    ))}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default ManageHeroes;
