import Globals from "../lookups/Globals";
import SiteData from "../types/SiteData";
import { getBanners, getFeats, getGames, getHeroAbilities, getHeroColors, getHeroMoveTypes, getHeroSources, getHeroSummoningPools, getHeroThemes, getHeroWeapons, getHeroes } from "./apiHelper";

async function fetchSiteData(): Promise<SiteData> {
    const siteData: SiteData = {
        heroes: [],
        banners: [],
        games: [],
        heroAbilities: [],
        heroColors: [],
        heroMoveTypes: [],
        heroSources: [],
        heroSummoningPools: [],
        heroThemes: [],
        heroWeapons: [],
        feats: []
    }

    siteData.heroes = (await getHeroes()).sort((a, b) => {
        const dateA = a.firstAvailable ?? Globals.FEH_APP_LAUNCH_DATE;
        const dateB = b.firstAvailable ?? Globals.FEH_APP_LAUNCH_DATE;
        if (dateA < dateB) {
            return 1;
        }
        else if (dateB < dateA) {
            return -1;
        }
        return 0;
    });

    siteData.banners = (await getBanners()).sort((a, b) => {
        const dateA = a.startDate ?? Globals.FEH_APP_LAUNCH_DATE;
        const dateB = b.startDate ?? Globals.FEH_APP_LAUNCH_DATE;
        if (dateA < dateB) {
            return 1;
        }
        else if (dateB < dateA) {
            return -1;
        }
        return 0;
    });

    siteData.games = await getGames();
    siteData.heroAbilities = await getHeroAbilities();
    siteData.heroColors = await getHeroColors();
    siteData.heroMoveTypes = await getHeroMoveTypes();
    siteData.heroSources = await getHeroSources();
    siteData.heroSummoningPools = (await getHeroSummoningPools()).sort((a, b) => a.name.localeCompare(b.name))
    siteData.heroThemes = (await getHeroThemes()).sort((a, b) => a.name.localeCompare(b.name));
    siteData.heroWeapons = await getHeroWeapons();
    siteData.feats = await getFeats();

    return siteData;
}

export {
    fetchSiteData
}