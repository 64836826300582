import "./WishlistButton.scss";

type WishlistButtonProps = {
  active: boolean;
  onChange: () => void;
};

function WishlistButton(props: WishlistButtonProps) {
  const uiImageRoot = `${process.env.REACT_APP_STORAGE_URL}ui`;

  let classes = "wishlist-button";
  if (!props.active) {
    classes += " wishlist-button--inactive";
  }

  return (
    <img
      className={classes}
      src={`${uiImageRoot}/heart.png`}
      alt="Toggle wishlist state"
      onClick={props.onChange}
      loading="lazy"
    />
  );
}

export default WishlistButton;
