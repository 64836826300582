export const saveToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const loadFromLocalStorage = <T>(key: string): T | null => {
    const storageString = localStorage.getItem(key);

    if (!storageString) {
        return null;
    }

    return JSON.parse(storageString) as T;
}

export const isInLocalStorage = (key: string) => {
    return localStorage.getItem(key) !== null
}