import { useReducer } from "react";
import { Action } from "./actions";
import { createContext } from "use-context-selector";

import { filtersInitialState, filtersReducer, FiltersState } from "./sections/filters";
import { siteDataInitialState, siteDataReducer, SiteDataState } from "./sections/siteData";
import { settingsInitialState, settingsReducer, SettingsState } from "./sections/settings";
import { collectionInitialState, collectionReducer, CollectionState } from "./sections/collection";
import { navigationInitialState, navigationReducer, NavigationState } from "./sections/navigation";
import { adminInitialState, adminReducer, AdminState } from "./sections/admin";

export type Dispatch = (action: Action) => void;
type Reducer<T> = (state: T, action: Action) => T;

interface Reducers {
    filters: Reducer<FiltersState>,
    siteData: Reducer<SiteDataState>,
    settings: Reducer<SettingsState>,
    collection: Reducer<CollectionState>,
    navigation: Reducer<NavigationState>,
    admin: Reducer<AdminState>
}

export type State = {
    filters: FiltersState,
    siteData: SiteDataState,
    settings: SettingsState,
    collection: CollectionState,
    navigation: NavigationState,
    admin: AdminState
}

const initialState: State = {
    filters: filtersInitialState,
    siteData: siteDataInitialState,
    settings: settingsInitialState,
    collection: collectionInitialState,
    navigation: navigationInitialState,
    admin: adminInitialState
}

const combineReducers = <TReducers extends object, TState, TAction>(reducers: TReducers) =>
    (state: TState, action: TAction): TState => {
        const reducerKeys = Object.keys(reducers);

        return reducerKeys.reduce((accumulator, key) => {
            const getState = () => {
                if ((process.env.REACT_APP_ENABLE_LOGGING ?? "false").toLowerCase() === "true") {
                    console.log("Action: ", (action as any).type)
                }

                // @ts-ignore
                return reducers[key](state[key], action)
            }

            // @ts-ignore
            accumulator[key] = getState();
            return accumulator;
        }, {} as TState)
    }

const combinedReducer = combineReducers<Reducers, State, Action>({
    filters: filtersReducer,
    siteData: siteDataReducer,
    settings: settingsReducer,
    collection: collectionReducer,
    navigation: navigationReducer,
    admin: adminReducer
})

export const useAppReducer = (): [State, Dispatch] => useReducer(combinedReducer, initialState);

export const AppStateContext = createContext<[State, Dispatch]>([initialState, () => null]);