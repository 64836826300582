import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiBanner from "../../types/api/ApiBanner";
import BannerInfo from "../../types/BannerInfo";
import FeedbackType from "../../types/Feedback";
import HeroInfo from "../../types/HeroInfo";
import Feedback from "../../components/Feedback";
import { getDate } from "../../helpers/dateHelper";
import { useAdmin, useDispatch } from "../../reducer/hooks";
import PageTitle from "../../components/PageTitle";
import TextDataField from "../../components/TextDataField";
import DatepickerDataField from "../../components/DatepickerDataField";
import Globals from "../../lookups/Globals";

const fetchBannerData = async (id: string): Promise<BannerInfo> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/Banner/${id}`);
  if (response.ok) {
    const banner = (await response.json()) as BannerInfo;
    return banner;
  }
  return {} as BannerInfo;
};

type LookupData = {
  heroes: HeroInfo[];
};

const fetchLookupData = async (): Promise<LookupData> => {
  var data = {
    heroes: [],
  };

  const heroesResponse = await fetch(`${process.env.REACT_APP_API_URL}/Hero/`);
  if (heroesResponse.ok) {
    data.heroes = await heroesResponse.json();
  }

  return data;
};

function ManageBanner() {
  const params = useParams();
  let id = params.id ?? "";
  let isNew = !!!id;

  const { adminPassword } = useAdmin();
  const dispatch = useDispatch();

  const currentDate = new Date();
  const tomorrowDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    7,
    0,
    0,
  );

  const [banner, setBanner] = useState<BannerInfo>({
    startDate: tomorrowDate,
    heroes: [],
  } as unknown as BannerInfo);

  const [lookupData, setLookupData] = useState<LookupData>(
    {} as unknown as LookupData,
  );
  const [feedback, setFeedback] = useState<FeedbackType | null>(null);

  const [filterHeroesByNoBanner, setFilterHeroesByNoBanner] =
    useState<boolean>(true);

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      loading: loading,
    });
  };

  useEffect(() => {
    setLoading(true);

    fetchLookupData().then((data) => {
      setLookupData(data);
    });

    if (id) {
      fetchBannerData(id).then((data) => {
        setBanner(data);
        setLoading(false);
      });
    } else {
      setBanner({ ...banner, name: "New Banner" });
      setLoading(false);
    }
  }, [id]);

  const validate = () => {
    const errors = [];

    // Check for name
    if (!banner.name) {
      errors.push("Missing name");
    }

    // Check for short ID
    if (!banner.shortId) {
      errors.push("Missing short ID");
    }

    // Check for date
    if (!banner.startDate) {
      errors.push("Missing date");
    }

    // Check for heroes
    if (!banner.heroes) {
      errors.push("Add at least one hero");
    }

    return errors;
  };

  const saveBannerData = async () => {
    setFeedback(null);

    const errors = validate();
    if (errors.length) {
      setFeedback({
        type: "Negative",
        message: errors.join(", "),
      });
      return;
    }

    setLoading(true);

    const data: ApiBanner = {
      shortId: banner.shortId,
      name: banner.name,
      startDate: getDate(banner.startDate) as Date,
      heroShortIds: (banner.heroes ?? []).map((x) => x.shortId),
    };

    const options = {
      method: isNew ? "POST" : "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8",
        password: adminPassword,
      },
      body: JSON.stringify(data),
    };

    const url = `${process.env.REACT_APP_API_URL}/Banner/${id}`;

    const response = await fetch(url, options);
    setLoading(false);

    if (response.status < 200 || response.status > 299) {
      setFeedback({
        type: "Negative",
        message: `${isNew ? "Create" : "Update"} failed (Status code: ${
          response.status
        })`,
      });
      console.log(options);
      return;
    }

    isNew = false;
    id = banner.shortId;

    setFeedback({
      type: "Positive",
      message: `${isNew ? "Create" : "Update"} successful (Status code: ${
        response.status
      })`,
    });
  };

  const setGeneratedShortId = () => {
    if (!!!banner.name) return;

    const newShortId = generateShortId();

    setBanner({ ...banner, shortId: newShortId });
  };

  const generateShortId = () => {
    if (!!!banner.name) return "";

    let newShortId = banner.name
      .trim()
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(":", "")
      .replaceAll("-", "")
      .replaceAll("&", "and")
      .replaceAll("  ", " ")
      .replaceAll(" ", "-");

    newShortId = newShortId.toLowerCase();

    return newShortId;
  };

  const bannerDate = getDate(banner.startDate) as Date;
  let filteredDropdownHeroes = lookupData.heroes ?? [];
  if (filterHeroesByNoBanner) {
    filteredDropdownHeroes = filteredDropdownHeroes.filter(
      (x) => !x.bannerId || x.bannerId <= 0,
    );
  }
  filteredDropdownHeroes = filteredDropdownHeroes.filter(
    (x) =>
      (banner.heroes ?? []).map((y) => y.shortId).indexOf(x.shortId) <= -1 &&
      x.heroSource.id === 1,
  );

  return (
    <>
      <PageTitle>{banner.name}</PageTitle>

      <div>
        <button onClick={setGeneratedShortId} disabled={!!banner.shortId}>
          Generate Short ID
        </button>
        <button onClick={saveBannerData}>Save</button>
      </div>
      <hr />
      {!!feedback && (
        <div>
          <Feedback feedback={feedback} />
        </div>
      )}
      <div>
        <TextDataField
          label="Banner Name"
          value={banner.name}
          onChange={(value) => {
            setBanner({ ...banner, name: value });
          }}
        />
        <TextDataField
          label="Short ID"
          value={banner.shortId}
          placeholder={generateShortId()}
          onChange={(value) => {
            setBanner({ ...banner, shortId: value });
          }}
        />
        <DatepickerDataField
          label="Start date"
          value={bannerDate}
          onChange={(date) => {
            if (!!date) {
              date.setHours(7);
            }
            setBanner({
              ...banner,
              startDate: date ?? Globals.FEH_APP_LAUNCH_DATE,
            });
          }}
        />
      </div>
      <hr />
      <h3>Heroes</h3>
      {(banner.heroes ?? []).map((x) => (
        <div>
          <span>
            {x.characterName}: {x.title}
          </span>
          <button
            onClick={() => {
              let bannerHeroes = banner.heroes ?? [];
              bannerHeroes = bannerHeroes?.filter(
                (y) => y.shortId != x.shortId,
              );
              setBanner({ ...banner, heroes: bannerHeroes });
            }}
          >
            Remove
          </button>
        </div>
      ))}
      <hr />
      <div>
        <select id="heroSelect">
          <option key="hero-none" value=""></option>
          {filteredDropdownHeroes.map((hero) => {
            return (
              <option key={`hero-${hero.shortId}`} value={hero.shortId}>
                {hero.characterName}: {hero.title}
              </option>
            );
          })}
        </select>
        <button
          onClick={() => {
            const selectedHeroShortId = (
              document.querySelector("#heroSelect") as HTMLSelectElement
            ).value;
            if (!selectedHeroShortId) return;

            const selectedHero = filteredDropdownHeroes.find(
              (x) => x.shortId == selectedHeroShortId,
            );
            if (!selectedHero) return;

            const bannerHeroes = banner.heroes ?? [];
            bannerHeroes.push(selectedHero);

            setBanner({ ...banner, heroes: bannerHeroes });
          }}
        >
          Add
        </button>
        <input
          id="heroFilterCheckbox"
          type="checkbox"
          checked={!filterHeroesByNoBanner}
          onChange={() => {
            setFilterHeroesByNoBanner(!filterHeroesByNoBanner);
          }}
        />
        <label htmlFor="heroFilterCheckbox">Show all summonable heroes?</label>
      </div>
    </>
  );
}

export default ManageBanner;
