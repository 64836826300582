import { ChangeEvent, useMemo } from "react";
import DataField from "../DataField";
import { CommonDataFieldProps } from "../DataField/DataField";
import { generateRandomGuid } from "../../helpers/guidHelper";

export type SelectDataFieldItem = { text: string; value: string | number };

type SelectDataFieldProps = CommonDataFieldProps<string | number, string> & {
  options: SelectDataFieldItem[];
};

export const SelectDataField = ({
  id,
  label,
  value,
  options,
  onChange,
}: SelectDataFieldProps) => {
  const memoId = useMemo(() => id ?? generateRandomGuid(), [id]);

  return (
    <DataField id={memoId} label={label}>
      <select
        id={memoId}
        value={value}
        onChange={(event: ChangeEvent) => {
          // TODO: Is default "0" okay for all uses?
          const selectedValue = (event.target as any).value ?? "0";
          onChange(selectedValue);
        }}
      >
        {options.map((x) => (
          <option key={`${id}-${x.value}`} value={x.value}>
            {x.text}
          </option>
        ))}
      </select>
    </DataField>
  );
};
