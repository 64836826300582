import { ReactNode } from "react";

import "./TabGroup.scss";

type TabGroupProps = {
    children: ReactNode
}

function TabGroup(props: TabGroupProps) {
    return <div className="tab-group">
        {props.children}
    </div>
}

export default TabGroup;