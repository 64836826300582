import FeatInfo from "../../types/FeatInfo";
import HeroInfo from "../../types/HeroInfo";
import { hasFeat } from "../../helpers/featHelper";

import "./FeatTile.scss";
import { useCollection } from "../../reducer/hooks";

type FeatTileProps = {
  feat: FeatInfo;
  heroData: HeroInfo[];
};

function FeatTile(props: FeatTileProps) {
  const { collection } = useCollection();

  const data = hasFeat(props.feat, props.heroData, collection);
  const obtained = data[0] as boolean;
  const progress = data[1] as number;

  const progressBar = (
    <div className="feat-tile__progress-bar-outer">
      <div
        className="feat-tile__progress-bar-inner"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );

  const featImageSrc = obtained
    ? props.feat.obtainedImageUrl
    : props.feat.unobtainedImageUrl;

  const featImageAlt = obtained ? "Obtained feat" : "Unobtained feat";

  return (
    <div className={`feat-tile ${obtained ? "feat-tile--obtained" : ""}`}>
      <div className="feat-tile__top-half">
        <img src={featImageSrc} alt={featImageAlt} />
      </div>
      <div className="feat-tile__bottom-half">
        <div>
          <b>{props.feat.name}</b>
          <p>{props.feat.description}</p>
        </div>
        {!obtained && progressBar}
      </div>
    </div>
  );
}

export default FeatTile;
