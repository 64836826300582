import "./DisplaySwitcher.scss";

type DisplaySwitcherProps = {
  displayType: string;
  updateDisplayType: (type: string) => void;
};

function DisplaySwitcher(props: DisplaySwitcherProps) {
  const iconImageRoot = `${process.env.REACT_APP_STORAGE_URL}icons`;

  let imgSrc = "";
  if (props.displayType === "table") {
    imgSrc = `${iconImageRoot}/table.svg`;
  } else if (props.displayType === "grid") {
    imgSrc = `${iconImageRoot}/grid.svg`;
  }

  const clickEvent = () => {
    if (props.displayType === "table") {
      props.updateDisplayType("grid");
    } else if (props.displayType === "grid") {
      props.updateDisplayType("table");
    }
  };

  const keypressEvent = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      clickEvent();
    }
  };

  return (
    <div
      onClick={clickEvent}
      onKeyPress={keypressEvent}
      className="display-switcher"
      tabIndex={0}
    >
      <span>
        {props.displayType.substring(0, 1).toUpperCase()}
        {props.displayType.substring(1)}
      </span>
      <img src={imgSrc} alt="" />
    </div>
  );
}

export default DisplaySwitcher;
