import { Action } from "../actions";

export type NavigationState = {
    loading: boolean,
    broken: boolean,
    showManageLink: boolean
};

export const navigationInitialState: NavigationState = {
    loading: false,
    broken: false,
    showManageLink: false
}

export const navigationReducer = (state: NavigationState, action: Action): NavigationState => {
    switch (action.type) {
        case "SET_LOADING":
            return { ...state, loading: action.loading };
        case "SET_BROKEN":
            return { ...state, broken: action.broken };
        case "SET_MANAGE_LINK_STATE":
            return { ...state, showManageLink: action.show };
        default:
            return state;
    }
}