import { Link, useParams } from "react-router-dom";
import Globals from "../../lookups/Globals";
import HeroInfo from "../../types/HeroInfo";
import { formatDateAsString } from "../../helpers/dateHelper";
import { useSiteData } from "../../reducer/hooks";
import PageTitle from "../../components/PageTitle";
import DataTerm from "../../components/DataTerm";
import HeroImagePane from "../../components/HeroImagePane";

function Hero() {
  const params = useParams();
  const id = params.id ?? "";

  const siteData = useSiteData();

  const hero = siteData.heroes.find((x) => x.shortId === id);
  if (!hero) {
    if (siteData.heroes.length) {
      return <span>Hero not found</span>;
    }
    return <></>;
  }

  // TODO: Moved to shared file already!!!!!
  const heroSortByDateFunction = (a: HeroInfo, b: HeroInfo) => {
    const dateA =
      !!!a.firstAvailable || typeof a.firstAvailable === "string"
        ? Globals.FEH_APP_LAUNCH_DATE
        : a.firstAvailable;
    const dateB =
      !!!b.firstAvailable || typeof b.firstAvailable === "string"
        ? Globals.FEH_APP_LAUNCH_DATE
        : b.firstAvailable;

    if (dateA > dateB) {
      return -1;
    } else if (dateA < dateB) {
      return 1;
    }
    return 0;
  };

  const listItemsForRelatedHeroes = (relatedHero: HeroInfo) => {
    if (relatedHero.shortId !== hero.shortId) {
      return (
        <li>
          <Link to={`/Hero/${relatedHero.shortId}`}>
            {relatedHero.characterName}: {relatedHero.title}
          </Link>
        </li>
      );
    } else {
      return (
        <li>
          {relatedHero.characterName}: {relatedHero.title}
        </li>
      );
    }
  };

  const relatedHeroes = siteData.heroes
    .filter((x) => x.characterName === hero.characterName)
    .sort(heroSortByDateFunction);
  const backupCharacterRelatedHeroes = siteData.heroes.filter(
    (x) => x.characterName === hero.backupCharacterName,
  );
  const isBackupCharacterElsewhereRelatedHeroes = siteData.heroes
    .filter((x) => x.backupCharacterName === hero.characterName)
    .sort(heroSortByDateFunction);

  // TODO: Good spot to implement different display name logic later on :)
  const heroDisplayName = `${hero.characterName}: ${hero.title}`;

  return (
    <>
      <PageTitle>{heroDisplayName}</PageTitle>
      <hr />
      <div className="hero-bio">
        <div className="hero-bio__image-panel">
          <HeroImagePane heroName={heroDisplayName} images={hero.images.full} />
        </div>
        <div className="hero-bio__info-panel">
          <h3>Info</h3>
          <DataTerm label="Colour" value={hero.heroColor.name} />
          <DataTerm label="Weapon" value={hero.heroWeapon.name} />
          <DataTerm label="Move Type" value={hero.heroMoveType.name} />
          <DataTerm
            label="Category"
            value={`${hero.heroSource.name} ${
              hero.heroSummoningPool.id !== 1
                ? ` - ${hero.heroSummoningPool.name}`
                : ""
            }`}
          />
          {hero.heroTheme && (
            <DataTerm label="Theme" value={hero.heroTheme?.name} />
          )}
          <DataTerm
            label="Game(s)"
            value={
              hero.heroGames
                ? hero.heroGames
                    .map((game) => {
                      const gameName = siteData.games.find(
                        (x) => x.id === game.gameId,
                      )?.title;
                      return gameName;
                    })
                    .join(",")
                : "N/A"
            }
          />
          <DataTerm
            label="First available"
            value={formatDateAsString(hero.firstAvailable)}
          />
          <DataTerm
            label="Version"
            value={
              (hero.bookNumber ?? -1) >= 0 && (hero.chapterNumber ?? -1) >= 0
                ? `${hero.bookNumber}.${hero.chapterNumber}`
                : "Unknown"
            }
          />

          {(relatedHeroes.length > 1 ||
            backupCharacterRelatedHeroes.length > 0 ||
            isBackupCharacterElsewhereRelatedHeroes.length > 0) && (
            <>
              <hr />
              <div>
                <h3>Related heroes</h3>
                {relatedHeroes.length > 1 && (
                  <ul>{relatedHeroes.map(listItemsForRelatedHeroes)}</ul>
                )}
                {backupCharacterRelatedHeroes.length > 0 && (
                  <ul>
                    {backupCharacterRelatedHeroes.map(
                      listItemsForRelatedHeroes,
                    )}
                  </ul>
                )}
                {isBackupCharacterElsewhereRelatedHeroes.length > 0 && (
                  <ul>
                    {isBackupCharacterElsewhereRelatedHeroes.map(
                      listItemsForRelatedHeroes,
                    )}
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Hero;
