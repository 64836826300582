import { ReactNode } from "react";
import "./DataField.scss";

export type CommonDataFieldProps<TIn, TOut> = {
  id?: string;
  label: string;
  value: TIn;
  onChange: (value: TOut) => void;
};

type DataFieldProps = {
  id?: string;
  label: string;
  children: ReactNode;
};

export default ({ id, label, children }: DataFieldProps) => {
  return (
    <div className="data-field" key={`data-field-${id}`}>
      <label htmlFor={id}>{label}</label>
      {children}
    </div>
  );
};
