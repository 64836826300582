export default Object.freeze({
    Standard: 1,
    Legendary: 2,
    Mythic: 3,
    Duo: 4,
    Harmonized: 5,
    Ascended: 6,
    Rearmed: 7,
    Attuned: 8,
    Emblem: 9,
    Aided: 10
});