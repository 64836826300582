const getDate = (date: string | Date | undefined | null): Date | null => {
    if (date === null || date === undefined) {
        return null;
    }

    if (typeof date === "string") {
        return new Date(date);
    }

    return date as Date;
}

const formatDateAsString = (date: string | Date | null) => {
    const handledDate = getDate(date);

    if (handledDate === null) {
        return "";
    }

    return handledDate.toISOString();
}

export {
    getDate,
    formatDateAsString
}