import featObtainTypes from "../lookups/FeatObtainType";
import { CollectionHeroes } from "../types/CollectionData";
import CollectionHero from "../types/CollectionHero";
import FeatInfo from "../types/FeatInfo";
import HeroInfo from "../types/HeroInfo";
import { getCollectionCount, isInCollection } from "./collectionHelper";

function hasFeat(feat: FeatInfo, heroData: HeroInfo[], collection: CollectionHeroes) {
    switch (feat.obtainTypeId) {
        case featObtainTypes.CharacterCount:
            const characterName = feat.obtainKey;
            const heroesWithCharacter = heroData.filter(x => x.characterName === characterName);

            return workOutObtainedFromSubset(heroesWithCharacter, "Count", feat.obtainQuantity, collection);
        case featObtainTypes.BookPercentage:
        case featObtainTypes.BookCount:
            let bookNumber = parseInt(feat.obtainKey);
            if (!bookNumber) {
                bookNumber = 0;
            }

            let bookHeroes = [];
            if (bookNumber === 0) {
                bookHeroes = heroData.filter(x => x.bookNumber === 1 && x.chapterNumber === 0);
            }
            else if (bookNumber === 1) {
                bookHeroes = heroData.filter(x => x.bookNumber === 1 && (x.chapterNumber ?? 0) > 0);
            }
            else {
                bookHeroes = heroData.filter(x => x.bookNumber === bookNumber);
            }

            return workOutObtainedFromSubset(bookHeroes, feat.obtainTypeId === featObtainTypes.BookPercentage ? "Percentage" : "Count", feat.obtainQuantity, collection);
        case featObtainTypes.GamePercentage:
        case featObtainTypes.GameCount:
            let gameId = parseInt(feat.obtainKey);
            let gameHeroes = heroData.filter(x => !!x.heroGames.find(y => y.gameId === gameId));

            return workOutObtainedFromSubset(gameHeroes, feat.obtainTypeId === featObtainTypes.GamePercentage ? "Percentage" : "Count", feat.obtainQuantity, collection);
        case featObtainTypes.ThemePercentage:
        case featObtainTypes.ThemeCount:
            let themeId = parseInt(feat.obtainKey);
            let themeHeroes = heroData.filter(x => (x.heroTheme?.id ?? 0) === themeId);

            return workOutObtainedFromSubset(themeHeroes, feat.obtainTypeId === featObtainTypes.ThemePercentage ? "Percentage" : "Count", feat.obtainQuantity, collection);
        case featObtainTypes.SummoningPoolPercentage:
        case featObtainTypes.SummoningPoolCount:
            let summoningPoolId = parseInt(feat.obtainKey);
            let summoningPoolHeroes = heroData.filter(x => x.heroSummoningPool.id === summoningPoolId);

            return workOutObtainedFromSubset(summoningPoolHeroes, feat.obtainTypeId === featObtainTypes.SummoningPoolPercentage ? "Percentage" : "Count", feat.obtainQuantity, collection);
        case featObtainTypes.AbilityPercentage:
        case featObtainTypes.AbilityCount:
            let abilityId = parseInt(feat.obtainKey);
            let abilityHeroes = heroData.filter(x => x.heroAbility.id === abilityId);

            return workOutObtainedFromSubset(abilityHeroes, feat.obtainTypeId === featObtainTypes.AbilityPercentage ? "Percentage" : "Count", feat.obtainQuantity, collection);
        case featObtainTypes.WholeCollectionCount:
            const overallQuantityNeeded = feat.obtainQuantity;
            const quantity = getCollectionCount(collection);

            return [quantity >= overallQuantityNeeded, (quantity / overallQuantityNeeded) * 100];
        case featObtainTypes.WholeCollectionPercentage:
            const overallPercentageNeeded = feat.obtainQuantity;
            const percentage = (getCollectionCount(collection) / heroData.length) * 100;

            return [percentage >= overallPercentageNeeded, percentage];
        default:
            return [false, 0];
    }
}

type FeatCalculationType = "Count" | "Percentage"

const workOutObtainedFromSubset = (heroesToEvaluate: HeroInfo[], type: FeatCalculationType, obtainQuantity: number, collection: CollectionHeroes) => {
    let obtainedCount = 0;
    for (let hero of heroesToEvaluate) {
        if (!isInCollection(hero.shortId, collection)) continue;

        obtainedCount++;

        if (type === "Percentage") {
            const percentageNeeded = obtainQuantity;

            const currentPercentage = (obtainedCount / heroesToEvaluate.length) * 100;
            if (currentPercentage >= percentageNeeded) {
                return [true, 100];
            }
        }
        else {
            const countNeeded = obtainQuantity;

            if (obtainedCount >= countNeeded) {
                return [true, 100];
            }
        }
    }

    if (type === "Percentage") {
        return [false, (obtainedCount / heroesToEvaluate.length) * 100]
    }
    return [false, (obtainedCount / obtainQuantity) * 100];
}

export {
    hasFeat
}