import { convertDataIntoSaveFile, convertSaveFileIntoData } from "../../helpers/collectionHelper";
import { loadFromLocalStorage, saveToLocalStorage } from "../../helpers/localStorageHelper";
import Globals from "../../lookups/Globals";
import { collectionKey } from "../../lookups/LocalStorage";
import { CollectionData, CollectionHeroes, WishlistHeroes } from "../../types/CollectionData";
import CollectionSaveFile from "../../types/CollectionSaveFile";
import { Action } from "../actions";

export type CollectionState = CollectionData & {

};

export const collectionInitialState: CollectionState = {
    version: Globals.COLLECTION_CURRENT_VERSION,
    collection: new Map(),
    wishlist: new Map()
}

// const trimCollection = (validShortIds: string[]) => {
//     // Failsafe to make sure valid list of IDs is passed in
//     if (!validShortIds?.length || validShortIds.length <= 900) {
//         return;
//     }

//     // Loop through keys of collection map, and find invalid keys
//     const newCollectionMap = collection.collection;
//     const shortIdsToDrop = Array.from(newCollectionMap.keys()).filter(x => !validShortIds.includes(x));

//     // Remove any keys that need dropping
//     for (let shortId of shortIdsToDrop) {
//         newCollectionMap.delete(shortId);
//     }
//     updateCollection({ ...collection, collection: newCollectionMap });
// }

const addToMap = (shortIds: string[], map: CollectionHeroes | WishlistHeroes) => {
    for (let shortId of shortIds) {
        if (!map.has(shortId)) {
            map.set(shortId, {
                id: shortId,
                addedDate: new Date()
            });
        }
    }

    return map;
}

const removeFromMap = (shortIds: string[], map: CollectionHeroes | WishlistHeroes) => {
    for (let shortId of shortIds) {
        map.delete(shortId);
    }

    return map;
}

const handleUpdate = (newCollection: CollectionData) => {
    const saveFile = convertDataIntoSaveFile(newCollection);
    saveToLocalStorage(collectionKey, saveFile);

    return newCollection;
}

export const collectionReducer = (state: CollectionState, action: Action): CollectionState => {
    switch (action.type) {
        case "ADD_HEROES_TO_COLLECTION":
            const addCollectionMap = addToMap(action.shortIds, state.collection);
            return handleUpdate({ ...state, collection: addCollectionMap });
        case "REMOVE_HEROES_FROM_COLLECTION":
            const removeCollectionMap = removeFromMap(action.shortIds, state.collection);
            return handleUpdate({ ...state, collection: removeCollectionMap });
        case "ADD_HEROES_TO_WISHLIST":
            const addWishlistMap = addToMap(action.shortIds, state.wishlist);
            return handleUpdate({ ...state, wishlist: addWishlistMap });
        case "REMOVE_HEROES_FROM_WISHLIST":
            const removeWishlistMap = removeFromMap(action.shortIds, state.wishlist);
            return handleUpdate({ ...state, wishlist: removeWishlistMap });
        case "RESET_COLLECTION":
            return { ...collectionInitialState }
        case "IMPORT_COLLECTION":
            const importedData = convertSaveFileIntoData(action.saveFile)
            return handleUpdate({ ...collectionInitialState, ...importedData });
        case "LOAD_COLLECTION":
            let newState = { ...collectionInitialState };

            // Pull save file from storage and use if present
            const loadedSaveFile = loadFromLocalStorage<CollectionSaveFile>(collectionKey);
            if (loadedSaveFile) {
                const loadedCollection = convertSaveFileIntoData(loadedSaveFile);
                loadedCollection.collection ??= new Map();
                loadedCollection.wishlist ??= new Map();

                newState = { ...collectionInitialState, ...loadedCollection };
            }

            // TODO: Default version if not there?
            // TODO: Trim any fields not in initial state?
            // TODO: Re-implement short ID removal

            return newState;
        default:
            return state;
    }
}
