import { useContextSelector } from 'use-context-selector';
import { AppStateContext } from '.';

// State
export const useFilters = () => useContextSelector(AppStateContext, (v) => v[0].filters);

export const useSiteData = () => useContextSelector(AppStateContext, (v) => v[0].siteData);

export const useSettings = () => useContextSelector(AppStateContext, (v) => v[0].settings);

export const useCollection = () => useContextSelector(AppStateContext, (v) => v[0].collection);

export const useNavigation = () => useContextSelector(AppStateContext, (v) => v[0].navigation);

export const useAdmin = () => useContextSelector(AppStateContext, (v) => v[0].admin);

// Dispatching
export const useDispatch = () => useContextSelector(AppStateContext, (v) => v[1]);