import "./DataTerm.scss";

type DataTermProps = {
    label: string,
    value: string
}

export const DataTerm = ({ label, value }: DataTermProps) => {
    return <dl className="data-term">
        <dt>{label}</dt>
        <dd>{value}</dd>
    </dl>
}