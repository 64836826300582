import { useEffect } from "react";
import { useDispatch } from "../reducer/hooks";
import PageTitle from "../components/PageTitle";

const refreshPage = () => {
    window.location.href = "/";
}

function Error() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: false
        })
    }, []);

    return <>
        <PageTitle>Error</PageTitle>

        <p>An error occurred while getting that page for you, sorry about that!</p>
        <button onClick={refreshPage}>Refresh page</button>
    </>;
}

export default Error;