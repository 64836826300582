import { ChangeEvent, useEffect, useMemo } from "react";
import DataField from "../DataField";
import { CommonDataFieldProps } from "../DataField/DataField";
import { generateRandomGuid } from "../../helpers/guidHelper";

type TextDataFieldProps = CommonDataFieldProps<string | number, string> & {
  placeholder?: string;
};

export const TextDataField = ({
  id,
  label,
  value,
  placeholder,
  onChange,
}: TextDataFieldProps) => {
  const memoId = useMemo(() => id ?? generateRandomGuid(), [id]);

  return (
    <DataField id={memoId} label={label}>
      <input
        id={memoId}
        type="text"
        value={value}
        placeholder={placeholder ?? undefined}
        onChange={(event: ChangeEvent) => {
          const newValue = (event.target as any).value ?? "";
          onChange(newValue);
        }}
      />
    </DataField>
  );
};
