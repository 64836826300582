import { useEffect, useState } from "react";
import "./HeroImagePane.scss";
import HeroImageSet from "../../types/HeroImageSet";

type HeroImagePaneProps = {
  heroName: string;
  images: HeroImageSet;
};

export const HeroImagePane = ({ heroName, images }: HeroImagePaneProps) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    setImage(images.neutral);
  }, [images]);

  const changeImageIndex = (change: number) => {
    const imageList = [
      images.neutral,
      images.attack,
      images.special,
      images.damaged,
    ];
    const indexOf = imageList.indexOf(image);

    let newIndex = (indexOf + change) % imageList.length;
    if (newIndex < 0) {
      newIndex += imageList.length;
    }

    setImage(imageList[newIndex]);
  };

  return (
    <>
      <img src={image} alt={`${heroName} artwork`} />

      <div className="image-pane__button-area">
        <button
          className="image-pane__arrow"
          onClick={() => changeImageIndex(-1)}
          data-testid="back-button"
        >
          {"<"}
        </button>
        <button
          className="image-pane__arrow"
          onClick={() => changeImageIndex(1)}
        >
          {">"}
        </button>

        <button
          className="image-pane__button"
          disabled={image === images.neutral}
          onClick={() => setImage(images.neutral)}
        >
          Neutral
        </button>
        <button
          className="image-pane__button"
          disabled={image === images.attack}
          onClick={() => setImage(images.attack)}
        >
          Attack
        </button>
        <button
          className="image-pane__button"
          disabled={image === images.special}
          onClick={() => setImage(images.special)}
          data-testid="special-button"
        >
          Special
        </button>
        <button
          className="image-pane__button"
          disabled={image === images.damaged}
          onClick={() => setImage(images.damaged)}
        >
          Damaged
        </button>
      </div>
    </>
  );
};
