import { ChangeEvent, useEffect, useMemo } from "react";
import DataField from "../DataField";
import { CommonDataFieldProps } from "../DataField/DataField";
import { generateRandomGuid } from "../../helpers/guidHelper";
import { getDate } from "../../helpers/dateHelper";

type DatepickerDataFieldProps = CommonDataFieldProps<
  string | Date | undefined | null,
  Date | null
> & {};

export const DatepickerDataField = ({
  id,
  label,
  value,
  onChange,
}: DatepickerDataFieldProps) => {
  const memoId = useMemo(() => id ?? generateRandomGuid(), [id]);
  const memoDateValue = useMemo(() => getDate(value), [value]);

  return (
    <DataField id={memoId} label={label}>
      <input
        type="date"
        id={memoId}
        value={memoDateValue?.toISOString().split("T")[0]}
        onChange={(event: ChangeEvent) => {
          var dateString = ((event.target as any).value ?? "") as string;
          var date = new Date(dateString);
          onChange(date);
        }}
      />
    </DataField>
  );
};
