import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import {
  About, Checklist, Collection, Error, Feats, Hero, Home,
  ImportIndex, ManageBanner, ManageBanners, ManageHero,
  ManageHeroes, ManageIndex, Settings
} from './pages';
import NavBar from './components/NavBar/NavBar';
import LoadingCover from './components/LoadingCover';
import { fetchSiteData } from './helpers/siteDataHelper';
import { AppStateContext, useAppReducer } from './reducer';

import './App.scss';
import Globals from './lookups/Globals';
import { isInLocalStorage } from './helpers/localStorageHelper';
import { adminPasswordKey } from './lookups/LocalStorage';

const App = () => {
  const [state, dispatch] = useAppReducer();

  // TODO: Better way to pull these out of state?
  const { loading, broken } = state.navigation;

  const setLoading = (loading: boolean) => {
    dispatch({
      type: "SET_LOADING",
      loading: loading
    })
  }

  const setBroken = (broken: boolean) => {
    dispatch({
      type: "SET_BROKEN",
      broken: broken
    })
  }

  const refreshSiteData = () => {
    setLoading(true);
    fetchSiteData()
      .then(data => {
        // Finish loading
        setLoading(false);

        // Set site data
        dispatch({
          type: "SET_SITE_DATA",
          data: data
        });
      })
      .catch(err => {
        console.error(err);
        setBroken(true);
      })
  }

  // Initial page load tasks
  useEffect(() => {
    // Fetch site data from server
    refreshSiteData();

    // Load collection
    dispatch({
      type: "LOAD_COLLECTION"
    });

    // Load site settings
    dispatch({
      type: "LOAD_SETTINGS"
    });

    if (isInLocalStorage(adminPasswordKey)) {
      dispatch({
        type: "LOAD_ADMIN_PASSWORD"
      });
      dispatch({
        type: "SET_MANAGE_LINK_STATE",
        show: true
      })
    }
  }, []);

  const inManageArea = window.location.href.includes("/Manage");

  return (
    <div className="App">
      <AppStateContext.Provider value={[state, dispatch]}>
        <Router>
          <header>
            <div className="width-bounder">
              <div className='heading-flex-box'>
                <h1>{Globals.WEBSITE_NAME}</h1>
                {!loading && inManageArea && <button onClick={refreshSiteData}>Refresh data</button>}
              </div>
              {!broken && <NavBar />}
            </div>
          </header>
          <main>
            {!broken ? <>
              <div className="width-bounder">
                <ErrorBoundary onError={() => setBroken(true)} fallback={<Error />}>
                  <Routes>
                    <Route path='/' element={<Home />} />

                    <Route path='/Hero/:id' element={<Hero />} />

                    <Route path='/Collection' element={<Collection />} />

                    <Route path='/Checklist' element={<Checklist />} />

                    <Route path='/Feats' element={<Feats />} />

                    <Route path='/Manage' element={<ManageIndex />} />
                    <Route path='/Manage/Heroes' element={<ManageHeroes />} />
                    <Route path='/Manage/Hero' element={<ManageHero />} />
                    <Route path='/Manage/Hero/:id' element={<ManageHero />} />
                    <Route path='/Manage/Banners' element={<ManageBanners />} />
                    <Route path='/Manage/Banner' element={<ManageBanner />} />
                    <Route path='/Manage/Banner/:id' element={<ManageBanner />} />
                    <Route path='/Manage/Import' element={<ImportIndex />} />

                    <Route path="/About" element={<About />} />
                    <Route path="/Settings" element={<Settings />} />
                  </Routes>
                </ErrorBoundary>
              </div>
              <LoadingCover active={loading} />
            </> : <>
              <Error />
            </>}
          </main>
          <footer>
            <div className="width-bounder">
            </div>
          </footer>
        </Router>
      </AppStateContext.Provider>
    </div >
  );
}

export default App;
