import { useEffect } from "react";
import "./LoadingSpinner.scss";

function LoadingSpinner() {
    useEffect(() => {
        const possibleColors = ["#B13E46", "#4FABD7", "#8DC57E", "#c3c1d2"];

        const styleElem = document.head.appendChild(document.createElement("style"));

        for (let i = 0; i < 5; i++) {
            const color = possibleColors[Math.floor(Math.random() * possibleColors.length)];

            styleElem.innerHTML += `
                .lds-roller div:nth-child(${i + 1}):after {
                    background: ${color};
                }\n\n
            `;
        }
    }, []);

    return <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
}

export default LoadingSpinner;