import FeedbackType from "../../types/Feedback";

import "./Feedback.scss";

type FeedbackProps = {
    feedback: FeedbackType
}

function Feedback(props: FeedbackProps) {
    return <p className={"feedback feedback--" + props.feedback.type.toLowerCase()}>
        {props.feedback.message}
    </p>;
}

export default Feedback;