import DisplaySwitcher from "../../DisplaySwitcher/DisplaySwitcher";
import { useDispatch, useSettings } from "../../../reducer/hooks";
import SiteSettings from "../../../types/SiteSettings";
import SelectDataField from "../../SelectDataField";
import { SelectDataFieldItem } from "../../SelectDataField/SelectDataField";
import DataTerm from "../../DataTerm";

function SettingsVisualsTab() {
  const settings = useSettings();
  const dispatch = useDispatch();

  const unobtainedStyleTypes = ["Show", "Fade"];
  const obtainedStyleTypes = [...unobtainedStyleTypes, "Hide"];
  const sectionStyleTypes = ["Show", "Hide"];
  const artTypes = ["Neutral", "Attack", "Special", "Damaged"];

  const mapFunc = (x: string): SelectDataFieldItem => {
    return {
      text: x,
      value: x.toLowerCase(),
    };
  };

  const unobtainedStyleOptions = unobtainedStyleTypes.map(mapFunc);
  const obtainedStyleOptions = obtainedStyleTypes.map(mapFunc);
  const sectionStyleOptions = sectionStyleTypes.map(mapFunc);
  const artOptions: SelectDataFieldItem[] = artTypes.map(mapFunc);

  const updateSettings = (newSettings: SiteSettings) =>
    dispatch({ type: "SET_SETTINGS", settings: { ...newSettings } });

  const updateDisplayType = (type: string) =>
    updateSettings({ ...settings, displayType: type as any });

  const updateUnobtainedStyle = (value: string) =>
    updateSettings({
      ...settings,
      unobtainedStyle: (value ?? "fade") as any,
    });
  const updateUnobtainedArt = (value: string) =>
    updateSettings({
      ...settings,
      unobtainedArt: (value ?? "attack") as any,
    });

  const updateObtainedStyle = (value: string) =>
    updateSettings({
      ...settings,
      obtainedStyle: (value ?? "show") as any,
    });
  const updateObtainedArt = (value: string) =>
    updateSettings({
      ...settings,
      obtainedArt: (value ?? "special") as any,
    });

  const updateCompletedSectionStyle = (value: string) =>
    updateSettings({
      ...settings,
      completedSectionStyle: (value ?? "special") as any,
    });

  return (
    <>
      <h3>Visuals</h3>
      <h4>Hero Display</h4>
      <DataTerm
        label="Display type"
        value={
          (
            <DisplaySwitcher
              displayType={settings.displayType}
              updateDisplayType={updateDisplayType}
            ></DisplaySwitcher>
          ) as any
        }
      ></DataTerm>
      <h4>Unobtained Heroes</h4>
      <div>
        <SelectDataField
          label="Style"
          value={settings.unobtainedStyle}
          onChange={updateUnobtainedStyle}
          options={unobtainedStyleOptions}
        />
        <SelectDataField
          label="Art"
          value={settings.unobtainedArt}
          onChange={updateUnobtainedArt}
          options={artOptions}
        />
      </div>
      <h4>Obtained Heroes</h4>
      <div>
        <SelectDataField
          label="Style"
          value={settings.obtainedStyle}
          onChange={updateObtainedStyle}
          options={obtainedStyleOptions}
        />
        <SelectDataField
          label="Art"
          value={settings.obtainedArt}
          onChange={updateObtainedArt}
          options={artOptions}
        />
      </div>
      <h4>Completed Sections</h4>
      <div>
        <SelectDataField
          label="Style"
          value={settings.completedSectionStyle}
          onChange={updateCompletedSectionStyle}
          options={sectionStyleOptions}
        />
      </div>
    </>
  );
}

export default SettingsVisualsTab;
