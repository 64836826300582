import { ReactNode } from "react";
import "./Modal.scss";

type ModalProps = {
    isOpen: boolean,
    children: ReactNode
}

const Modal = (props: ModalProps) => {
    if (!props.isOpen) return <></>;

    return <div className="modal__cover">
        <dialog className="modal__content">
            {props.children}
        </dialog>
    </div>
}

export default Modal;