import { useEffect, useState } from "react";

import SettingsTabs from "../lookups/SettingsTabs";
import TabGroup from "../components/TabGroup/TabGroup";
import { SettingsImportExportTab, SettingsVisualsTab } from "../components/Tabs";
import { useDispatch } from "../reducer/hooks";
import PageTitle from "../components/PageTitle";

function Settings() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: false
        });
    }, []);

    const [settingsTab, setSettingsTab] = useState<SettingsTabs>("Visuals");

    return <div>
        <PageTitle>Settings</PageTitle>

        <TabGroup>
            <button className={"tab-group__clickable " + (settingsTab === "Visuals" ? "tab-group__clickable--selected" : "")} onClick={() => setSettingsTab("Visuals")}>Visuals</button>
            <button className={"tab-group__clickable " + (settingsTab === "ImportExport" ? "tab-group__clickable--selected" : "")} onClick={() => setSettingsTab("ImportExport")}>Import / Export</button>

            <div className="tab-group__content">
                {
                    (settingsTab === "Visuals" && <SettingsVisualsTab />)
                    || (settingsTab === "ImportExport" && <SettingsImportExportTab />)
                }
            </div>

        </TabGroup>
    </div>
}

export default Settings;
