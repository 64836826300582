import GroupSortField from "../../lookups/GroupSortField";
import GroupingField from "../../lookups/GroupingField";
import HeroSortField from "../../lookups/HeroSortField";
import SelectDataField from "../SelectDataField";
import { SelectDataFieldItem } from "../SelectDataField/SelectDataField";

type HeroSortPanelProps = {
  heroSortField: HeroSortField;
  setHeroSortField: (field: HeroSortField) => void;
  groupSortField: GroupSortField;
  setGroupSortField: (field: GroupSortField) => void;
  groupingFieldHint: GroupingField | null;
};

function HeroSortPanel(props: HeroSortPanelProps) {
  const heroSortFieldsList: HeroSortField[] = [
    "Release Date",
    "Character Name",
  ];

  const groupSortFieldsList: GroupSortField[] = ["Count", "Name"];
  if (props.groupingFieldHint === "Banner") {
    groupSortFieldsList.push("Banner Release Date");
  }
  if (props.groupingFieldHint === "Book") {
    groupSortFieldsList.push("Book Number");
  }
  if (props.groupingFieldHint === "Chapter") {
    groupSortFieldsList.push("Chapter Number");
  }

  const mapFunc = (x: string): SelectDataFieldItem => {
    return {
      text: x,
      value: x,
    };
  };

  const showGroupSort = props.groupingFieldHint !== "None";

  return (
    <>
      <SelectDataField
        label="Sort heroes by"
        value={props.heroSortField}
        onChange={(value) => {
          props.setHeroSortField(value as any);
        }}
        options={heroSortFieldsList.map(mapFunc)}
      />
      {showGroupSort ? (
        <SelectDataField
          label="Sort groups by"
          value={props.groupSortField}
          onChange={(value) => {
            props.setGroupSortField(value as any);
          }}
          options={groupSortFieldsList.map(mapFunc)}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default HeroSortPanel;
