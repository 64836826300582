import { isInCollection } from "../../helpers/collectionHelper";
import { useCollection } from "../../reducer/hooks";
import HeroGroup from "../../types/HeroGroup";

import "./HeroChecklistGroup.scss";

type HeroChecklistGroupProps = {
    heroGroup: HeroGroup,
    side: boolean,
    base: boolean
}

function HeroChecklistGroup(props: HeroChecklistGroupProps) {
    const { collection } = useCollection();

    let groupClasses = "checklist__hero-group"
    if (props.side) {
        groupClasses += " checklist__hero-group--side"
    }
    if (props.base) {
        groupClasses += " checklist__hero-group--base";
    }

    return <div className={groupClasses}>
        <h4>{props.heroGroup.name}</h4>
        <table>
            <tbody>
                {props.heroGroup.heroes.map(hero => {
                    let cellClasses = "checklist__hero-group-cell";
                    if (isInCollection(hero.shortId, collection)) {
                        cellClasses += ` checklist__hero-group-cell--obtained`;
                    }

                    return <tr key={`herochecklistrow-${hero.shortId}`}>
                        <td className={cellClasses}>{hero.heroName}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default HeroChecklistGroup;