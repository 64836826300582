import "./Tick.scss";

type TickProps = {
  checked: boolean;
  onChange?: () => void;
};

function Tick(props: TickProps) {
  const uiImageRoot = `${process.env.REACT_APP_STORAGE_URL}ui`;

  let src = `${uiImageRoot}/tick-grey.png`;
  if (props.checked) {
    src = `${uiImageRoot}/tick-green.png`;
  }

  return (
    <img
      className="tick"
      src={src}
      alt="Toggle hero"
      onClick={props.onChange ?? (() => {})}
      loading="lazy"
    />
  );
}

export default Tick;
