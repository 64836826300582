import { NavLink } from "react-router-dom";
import { formatDateAsString } from "../../helpers/dateHelper";
import { useSiteData } from "../../reducer/hooks";
import PageTitle from "../../components/PageTitle";

function ManageBanners() {
    const siteData = useSiteData();

    const banners = siteData.banners;

    return <>
        <PageTitle>Manage Banners</PageTitle>

        <NavLink className="button" to="/Manage/Banner/">Add</NavLink>
        <table>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        First available
                    </th>
                </tr>
            </thead>
            <tbody>
                {banners.map((banner) =>
                    <tr key={banner.shortId}>
                        <td>
                            <NavLink to={`/Manage/Banner/${banner.shortId}`}>{banner.name}</NavLink>
                        </td>
                        <td>
                            {formatDateAsString(banner.startDate)}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </>;
}

export default ManageBanners;