import HeroGroup from "../types/HeroGroup";
import HeroChecklistBook from "../components/HeroChecklistBook/HeroChecklistBook";
import { useSiteData } from "../reducer/hooks";
import PageTitle from "../components/PageTitle";

function Checklist() {
    const { heroes } = useSiteData();

    const bookGroups: HeroGroup[] = [];
    for (let hero of heroes) {
        // Move heroes to separate base game group if they match
        if (hero.banner?.name.startsWith("Base Game")) {
            const baseGameId = "0";
            let baseGameGroup = bookGroups.find(x => x.id === baseGameId);
            if (!baseGameGroup) {
                baseGameGroup = {
                    id: baseGameId,
                    name: "Base Game",
                    heroes: []
                }
                bookGroups.push(baseGameGroup);
            }

            baseGameGroup.heroes.push(hero);

            continue;
        }

        // Otherwise, categorise by book
        let id = (hero.bookNumber ?? -1).toString();
        let group = bookGroups.find(x => x.id === id);
        if (!group) {
            group = {
                id,
                name: `Book ${id}` ?? "No book",
                heroes: []
            }
            bookGroups.push(group);
        }

        group.heroes.push(hero);
    }

    return <>
        <PageTitle>Checklist</PageTitle>

        {bookGroups.map(bookGroup => {
            return <HeroChecklistBook heroGroup={bookGroup} twoColumns={bookGroup.id !== "0"} />
        })}
    </>;
}

export default Checklist;