export default Object.freeze({
    Sword: 1,
    Lance: 2,
    Axe: 3,
    Bow: 4,
    Dagger: 5,
    Beast: 6,
    Tome: 7,
    Staff: 8,
    Dragon: 9
});