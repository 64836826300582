import { Helmet } from "react-helmet"
import Globals from "../../lookups/Globals"

type PageTitleProps = {
    children: string,
    showOnPageHeading?: boolean
}

export const PageTitle = ({ children, showOnPageHeading }: PageTitleProps) => {
    if (showOnPageHeading === undefined) {
        showOnPageHeading = true;
    }

    return <>
        <Helmet>
            <title>{children} - {Globals.WEBSITE_NAME}</title>
        </Helmet>

        {showOnPageHeading ? <h2>{children}</h2> : <></>}
    </>
}