import HeroGroup from "../components/HeroGroup/HeroGroup";
import Globals from "../lookups/Globals";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import HeroGroupType from "../types/HeroGroup";
import { useCollection, useSiteData } from "../reducer/hooks";
import { getCollectionCount, isInCollection, isInWishlist } from "../helpers/collectionHelper";
import PageTitle from "../components/PageTitle";

function Home() {
    const { heroes } = useSiteData();
    const { collection, wishlist } = useCollection();

    // Progress bar data
    const collectionCount = getCollectionCount(collection);
    const heroCount = heroes.length;

    // New heroes data
    const newHeroesGroup: HeroGroupType = {
        id: "new",
        name: "New Heroes!",
        heroes: []
    }
    if (!!heroes && !!heroes.length) {
        const newestReleaseDate = heroes[0].firstAvailable;
        const newestWeekStart = new Date(newestReleaseDate?.valueOf() ?? new Date().valueOf());
        newestWeekStart.setDate(newestWeekStart.getDate() - 3);
        const heroesInNewestWeek = heroes.filter(x => !!x.firstAvailable && new Date(x.firstAvailable ?? Globals.FEH_APP_LAUNCH_DATE.valueOf()) >= newestWeekStart).reverse();
        newHeroesGroup.heroes = heroesInNewestWeek;

        const newestHeroesBannerName = heroesInNewestWeek[0]?.banner?.name;
        if (newestHeroesBannerName) {
            newHeroesGroup.name += ` (${newestHeroesBannerName})`;
        }
    }

    // Wishlist data
    const wishlistGroup: HeroGroupType = {
        id: "wishlist",
        name: "Wishlist",
        heroes: []
    }

    if (!!heroes && !!heroes.length) {
        for (let hero of heroes) {
            if (isInWishlist(hero.shortId, wishlist) && !isInCollection(hero.shortId, collection)) {
                wishlistGroup.heroes.push(hero);
            }
        }
    }

    return <>
        <PageTitle>Home</PageTitle>

        <h3>Collection Completion</h3>
        <ProgressBar count={collectionCount} maximum={heroCount} />

        {!!newHeroesGroup.heroes.length && <>
            <hr />
            <HeroGroup group={newHeroesGroup} />
        </>}

        {!!wishlistGroup.heroes.length && <>
            <hr />
            <HeroGroup group={wishlistGroup} />
        </>}
    </>;
}

export default Home;